document.addEventListener("DOMContentLoaded", appMain);

function appMain() {
    burgerMenu();
    selectCustom();
    tabFun();
    formatNumber();
    allSlider();
    scrollHidden();
    quizStandart();
    maskPhone();
    submitHandler();
    initTooltips();
}


// маска телефона
function maskPhone() {
  let eventCallback = function (e) {
      let el = e.target,
          clearVal = el.dataset.phoneClear,
          pattern = el.dataset.phonePattern,
          matrix_def = "+7 (___) ___-__-__",
          matrix = pattern ? pattern : matrix_def,
          i = 0,
          def = matrix.replace(/\D/g, ""),
          val = e.target.value.replace(/\D/g, "");

      function hasMoreThanEightSameDigits(value) {
          let digitCounts = Array(10).fill(0);
          for (let digit of value) {
              digitCounts[digit]++;
              if (digitCounts[digit] > 8) {
                  return true;
              }
          }
          return false;
      }

      if (hasMoreThanEightSameDigits(val)) {
          e.target.value = e.target.value.slice(0, -1);
          return;
      }

      if (clearVal !== 'false' && e.type === 'blur') {
          if (val.length < matrix.match(/([\_\d])/g).length) {
              e.target.value = '';
              return;
          }
      }
      if (def.length >= val.length) val = def;
      e.target.value = matrix.replace(/./g, function (a) {
          return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a;
      });
  }

  let phone_inputs = document.querySelectorAll('input[name="phone"]');
  for (let elem of phone_inputs) {
      for (let ev of ['input', 'blur', 'focus']) {
          elem.addEventListener(ev, eventCallback);
      }
  }
}

// обработчик форм
function submitHandler() {
  const forms = document.querySelectorAll(".form");
  forms.forEach((form) => {
    form.addEventListener("submit", async (e) => {
      e.preventDefault();

      const fd = new FormData(form);
      fd.append("action", "sendmail");


      const nameInput = form.name;
      const phoneInput = form.phone;
      const checkbox = form.sogl;

      const isVal = validation([
        [
          phoneInput,
          (i) => i?.value.length !== 18,
          "Номер телефона указан неверно",
        ],
        [checkbox, (i) => !i?.checked, "Отметьте это поле"],
      ]);

      if (!isVal) return;

      const response = await fetch("/wp-admin/admin-ajax.php", {
        method: "POST",
        body: fd,
      });

      const fd2 = new FormData();
      fd2.append("Сайт", window.location.host);
      fd2.append("Телефон", form?.phone?.value || '----');
      fd2.append("Имя", form?.name?.value || '----');
      fd2.append("Тема", form?.theme?.value || '----');
      const params = new URLSearchParams(location.search);
      [...params.entries()].forEach((par) => {
        fd2.append(par[0], par[1]);
      });

      // передача цели метрики
      let dataSubmitFunc = new Function(form.getAttribute('data-submit'));

      if (response.ok) {
        
        // выполнение цели метрики
        dataSubmitFunc();

        form.reset();

        let formModal = document.querySelector('#formModal');
        let formTnx = document.querySelector('#formTnx');
        let modalOverlay = document.querySelector('.m23_overlay');
        let modalBusiness = document.querySelector('#modalBusiness');

        if (formModal) {
          formModal.classList.remove('active');
        }
        if (modalBusiness) {
          modalBusiness.classList.remove('active');
        }
        if (formTnx) {
          formTnx.classList.add('active');
        }
        if (modalOverlay) {
          modalOverlay.classList.add('active');
        }

      } else alert("Что-то пошло не так, попробуйте перезагрузить страницу!");

      const response2 = await fetch(
        "https://script.google.com/macros/s/AKfycbxX60xikG2JiSuUtWtjde6MOaWtLYA7p6SfhJGDuSFYUhRTB6eTN47IKUda4eOmJWDJ0A/exec",
        {
          method: "POST",
          body: fd2,
        }
      );

    });
  });
}

// валидация
function validation(items) {
  let isVal = true;
  items.forEach((el) => {
    const input = el[0];
    const cb = el[1];
    const text = el[2];

    const inputContainer = input?.closest(".form__input-container");
    const messageEl = inputContainer.querySelector(".form__message > span");

    if (cb(input)) {
      inputContainer.classList.add("message");
      messageEl.textContent = text;
      input.addEventListener("input", () => {
        inputContainer.classList.remove("message");
        messageEl.textContent = "";
      });
      isVal = false;
    }
  });
  return isVal;
}

// квиз
function quizStandart() {
    const nextButton = document.querySelector('.quiz__btn_next');
    if (!nextButton) return;

    const backButton = document.querySelector('.quiz__btn_back');
    const questionBlock = document.querySelector('[data-quiz="question"]');
    const finalBlock = document.querySelector('[data-quiz="final"]');
    const answersBlocks = document.querySelectorAll('.quiz__block__answers');
    const stepsStack = [];
    const quizImgInputs = document.querySelectorAll('.quiz__checkbox');
    const resultImageContainers = document.querySelectorAll('[data-quiz-img-result]');
    let currentImageSrc;
    let inputCounter = 1;

    function updateResultImageContainers(src) {
        resultImageContainers.forEach(container => {
            const img = container.querySelector('img');
            if (img) {
                img.setAttribute('src', src);
            }
            const source = container.querySelector('source');
            if (source) {
                source.setAttribute('srcset', src);
            }
        });
    }

    const activeSlide = document.querySelector('.quiz_slide.active');
    const checkedInput = activeSlide.querySelector('.quiz__checkbox__input:checked');
    if (checkedInput) {
        const parentLabel = checkedInput.closest('.quiz__checkbox');
        if (parentLabel) {
            currentImageSrc = parentLabel.getAttribute('data-quiz-img');
            updateResultImageContainers(currentImageSrc);
        }
    }
    function updateBackButtonVisibility() {
        const activeStep = document.querySelector('.quiz_slide.active').getAttribute('data-quiz-step');
        if (activeStep === '1') {
            backButton.classList.add('hidden');
        } else {
            backButton.classList.remove('hidden');
        }
    }

    // Вызываем при инициализации викторины
    updateBackButtonVisibility();

    function saveAndGoToNextQuestion() {
        const activeSlide = document.querySelector('.quiz_slide.active');
        const selectedInput = activeSlide.querySelector('.quiz__checkbox__input:checked');
        const nextStep = selectedInput ? selectedInput.getAttribute('data-quiz-next-change') || activeSlide.getAttribute('data-quiz-next') : activeSlide.getAttribute('data-quiz-next');
        stepsStack.push({ step: activeSlide.getAttribute('data-quiz-step'), from: selectedInput ? selectedInput.value : null });
        
    
        if (selectedInput) {
            const iconSrc = selectedInput.getAttribute('data-quiz-icon');
            const questionTitleElement = activeSlide.querySelector('.quiz__block__question__title');
            const answerTitle = questionTitleElement ? questionTitleElement.textContent : '';
            const answerValue = selectedInput.value;
        
        
            const hiddenBlocks = document.querySelectorAll('.quiz_hidden');
            hiddenBlocks.forEach(block => {
                const hiddenInput = document.createElement('input');
                hiddenInput.setAttribute('type', 'hidden');
                hiddenInput.setAttribute('name', `result${inputCounter}`);
                hiddenInput.value = answerTitle + ': ' + answerValue;
        
                block.appendChild(hiddenInput);
        
                inputCounter++;
            });
        
            answersBlocks.forEach(answersBlock => {
                const answerElement = document.createElement('div');
                answerElement.classList.add('quiz__block__answ');
        
                let innerHTML = '';
                if (iconSrc) {
                    innerHTML += `<img src="${iconSrc}" alt="">`;
                }
                innerHTML += `<span>${answerValue}</span>`;
        
                answerElement.innerHTML = innerHTML;
                answersBlock.appendChild(answerElement);
            });
        }

        if (nextStep === 'final') {
            questionBlock.classList.remove('active');
            finalBlock.classList.add('active');
        } else {
            activeSlide.classList.remove('active');
            document.querySelector(`.quiz_slide[data-quiz-step="${nextStep}"]`).classList.add('active');
            updateBackButtonVisibility();
        }
        updateImageOnQuestionChange();
    }

    nextButton.addEventListener('click', saveAndGoToNextQuestion);

    backButton.addEventListener('click', function () {
        answersBlocks.forEach(answersBlock => {
            const lastAnswer = answersBlock.lastElementChild;
            if (lastAnswer) {
                answersBlock.removeChild(lastAnswer);
            }
        });
        
        const hiddenBlocks = document.querySelectorAll('.quiz_hidden');
        
        hiddenBlocks.forEach(hiddenBlock => {
            const lastInput = hiddenBlock.querySelector('input:last-child');
            if (lastInput) {
                hiddenBlock.removeChild(lastInput);
                inputCounter--;
            }
        });

        if (stepsStack.length > 0) {
            const previous = stepsStack.pop();
            const currentSlide = document.querySelector('.quiz_slide.active');
            currentSlide.classList.remove('active');
            const previousSlide = document.querySelector(`.quiz_slide[data-quiz-step="${previous.step}"]`);
            previousSlide.classList.add('active');
            updateBackButtonVisibility();

            if (previous.from) {
                const inputToCheck = previousSlide.querySelector(`.quiz__checkbox__input[value="${previous.from}"]`);
                if (inputToCheck) {
                    inputToCheck.checked = true;
                }
            }

            updateImageOnQuestionChange();
        }
    });

    function updateImageOnQuestionChange() {
        const activeSlide = document.querySelector('.quiz_slide.active');
        const selectedInput = activeSlide.querySelector('.quiz__checkbox__input:checked');
        if (selectedInput) {
            const labelWithImage = selectedInput.closest('.quiz__checkbox');
            if (labelWithImage && labelWithImage.getAttribute('data-quiz-img')) {
                currentImageSrc = labelWithImage.getAttribute('data-quiz-img');
                updateResultImageContainers(currentImageSrc);

            }
        }
    }

    quizImgInputs.forEach(label => {
        let isClickHandled = false;

        label.addEventListener('mouseover', function () {
            const imgSrc = label.getAttribute('data-quiz-img');
            if (imgSrc) {
                updateResultImageContainers(imgSrc);
            }
        });

        label.addEventListener('mouseout', function () {
            if (currentImageSrc) {
                updateResultImageContainers(currentImageSrc);
            }
        });

        label.addEventListener('click', function () {
            if (isClickHandled) return;
            isClickHandled = true;
    
            const radioInput = label.querySelector('.quiz__checkbox__input');
            if (radioInput) {
                radioInput.checked = true;
                const imgSrc = label.getAttribute('data-quiz-img');
                if (imgSrc) {
                    currentImageSrc = imgSrc;
                    updateResultImageContainers(currentImageSrc);
                }
            }
    
            saveAndGoToNextQuestion();
            setTimeout(() => { isClickHandled = false; }, 300);
        });
    });
}

// бургер меню
function burgerMenu() {
    const menu = document.querySelector('.burger_menu__wrapper');
    if (!menu) return;

    const activeClass = ('is-active');
    const openMenuBtn = document.querySelectorAll('.burger_menu__btn');
    const closeMenuBtn = document.querySelectorAll('.burger_menu__btn__close');

    function openMenu(e) {
        e.preventDefault()

        if (menu.classList.contains(activeClass)) {
            menu.classList.remove(activeClass)
            closeMenuBtn.forEach(function (item) {
                item.classList.remove(activeClass)
            })
            openMenuBtn.forEach(function (item) {
                item.classList.add(activeClass)
            })
            document.body.style.cssText = 'overflow-y: inherit'
        } else {
            menu.classList.add(activeClass)
            closeMenuBtn.forEach(function (item) {
                item.classList.add(activeClass)
            })
            openMenuBtn.forEach(function (item) {
                item.classList.remove(activeClass)
            })
            document.body.style.cssText = 'overflow-y: hidden; padding-right: 0; width:100%'
        }
    }

    function closeMenu(e) {
        e.preventDefault()
        menu.classList.remove(activeClass)
        closeMenuBtn.forEach(function (item) {
            item.classList.remove(activeClass)
        })
        openMenuBtn.forEach(function (item) {
            item.classList.add(activeClass)
        })
        document.body.style.cssText = 'overflow-y: inherit'
    }

    function bodyClickHandler(evt = new Event('click')) {
        const el = evt.target || evt.currentTarget

        if (el && menu.classList.contains(activeClass) && !el.closest('.burger_menu__btn')) {
            menu.classList.remove(activeClass)
            closeMenuBtn.forEach(function (item) {
                item.classList.remove(activeClass)
            })
            openMenuBtn.forEach(function (item) {
                item.classList.add(activeClass)
            })
            document.body.style.cssText = 'overflow-y: inherit; padding: 0'
        }
    }

    openMenuBtn.forEach(function (item) {
        item.classList.add(activeClass)
        item.addEventListener('click', openMenu)
    })

    document.addEventListener('click', bodyClickHandler)

    if (closeMenuBtn != null) {
        closeMenuBtn.forEach(function (item) {
            item.addEventListener('click', closeMenu)
        })
    }
}

// кастомизация select
function selectCustom() {
    const select = document.querySelectorAll('.select');
    if (!select.length) return;

    select.forEach(function (item) {

        const selectHead = item.querySelector('.select__head');
        const selectList = item.querySelector('.select__list');
        const selectInput = item.querySelector('.select__input');

        selectHead.addEventListener('click', function () {
            select.forEach(function (otherItem) {
                if (otherItem !== item) {
                    const otherSelectHead = otherItem.querySelector('.select__head');
                    const otherSelectList = otherItem.querySelector('.select__list');
                    otherSelectHead.classList.remove('open');
                    otherSelectList.removeAttribute('style');
                }
            });

            if (selectHead.classList.contains('open')) {
                selectHead.classList.remove('open');
                selectList.removeAttribute('style');
            } else {
                selectHead.classList.add('open');
                selectList.setAttribute('style', 'display: block');

                const selectItem = item.querySelectorAll('.select__item');
                selectItem.forEach(function (item) {
                    item.addEventListener('click', function () {
                        selectHead.innerHTML = item.innerHTML;
                        selectInput.setAttribute('value', item.getAttribute('data-value'));

                        selectHead.classList.remove('open');
                        selectList.removeAttribute('style');
                    });
                });
            }
        });

        function bodyClickHandler(evt = new Event('click')) {
            const el = evt.target || evt.currentTarget;

            if (el && !el.closest('.select')) {
                selectHead.classList.remove('open');
                selectList.removeAttribute('style');
            }
        }

        document.addEventListener('click', bodyClickHandler);
    });
}

// доавление разрядности инпутам с ценой
function formatNumber() {
    const range = document.getElementById("myRange");
    const input = document.getElementById("demo");
    if (!range && !input && !document.querySelector('.form__input_price')) return;

    function formatNumberWithSpaces(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    function updateRangeFromInput(input, range) {
        range.value = input.value.replace(/\s/g, '');
    }

    function updateInputFromRange(input, range) {
        input.value = formatNumberWithSpaces(range.value);
    }

    function setupInputRangeInteraction(input, range) {
        if (input && range) {
            updateInputFromRange(input, range);

            range.addEventListener('input', function () {
                updateInputFromRange(input, range);
            });

            input.addEventListener('input', function () {
                updateRangeFromInput(input, range);
            });

            input.addEventListener('blur', function () {
                applyMinMax(input);
            });
        }
    }

    function applyMinMax(input) {
        let numericValue = parseInt(input.value.replace(/\s/g, ''), 10);
        let min = parseInt(input.getAttribute('min'), 10);
        let max = parseInt(input.getAttribute('max'), 10);

        if (!isNaN(min) && numericValue < min) {
            numericValue = min;
        } else if (!isNaN(max) && numericValue > max) {
            numericValue = max;
        }

        input.value = formatNumberWithSpaces(numericValue.toString());
    }

    setupInputRangeInteraction(input, range);

    let rangeYear = document.getElementById("myRangeYear");
    let inputYear = document.getElementById("demoYear");
    setupInputRangeInteraction(inputYear, rangeYear);

    document.querySelectorAll('.form__input_price').forEach(input => {
        input.addEventListener('input', function () {
            input.value = input.value.replace(/[^0-9\s]/g, '');
        });

        input.addEventListener('blur', function () {
            applyMinMax(input);
        });
    });
}

// табы
function tabFun() {
    const tabList = document.querySelector('.tabs');
    if (!tabList) return;

    let activeClass = ('is-active')

    function initTab() {
        if (tabsLink.length && tabsPages.length) {
            tabsLink[0].classList.add(activeClass)
            tabsPages[0].classList.add(activeClass)

            Array.prototype.forEach.call(tabsLink, tab => {
                tab.addEventListener('click', tabClickHandler)
            })
        }
    }

    function tabClickHandler(evt = new Event('click')) {
        evt.preventDefault()
        const tab = evt.currentTarget || evt.target

        if (tab && !tab.classList.contains(activeClass)) {
            const id = tab.dataset.tab;

            Array.prototype.forEach.call(tabsLink, t => {
                if (t.dataset.tab === id) {
                    t.classList.add(activeClass)
                } else {
                    t.classList.remove(activeClass)
                }
            })

            Array.prototype.forEach.call(tabsPages, p => {
                if (p.dataset.page === id) {
                    p.classList.add(activeClass)
                } else {
                    p.classList.remove(activeClass)
                }
            })
        }
    }

    const tabsLink = document.querySelectorAll('[data-tab]')
    const tabsPages = document.querySelectorAll('[data-page]')

    if (tabList) {
        initTab();
    }
}

// слайдеры
function allSlider() {
    const tabsSliderEl = document.querySelector('.tabs__slider');
    const sliderMobEl = document.querySelector('.slider_mob');
    const heroscreenSliderEl = document.querySelector('.heroscreen_slider');
    const mySwiperEl = document.querySelector('.mySwiper');
    const mySwiper2El = document.querySelector('.mySwiper2');

    if (tabsSliderEl) {
        const tabs_slider = new Swiper('.tabs__slider', {
            slidesPerView: 1,
            loop: true,
            parallax: true,
            pagination: {
                el: '.tabs__slider__pagination',
                clickable: true
            },
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: '.tabs__slide__arrow__next',
                prevEl: '.tabs__slide__arrow__prev',
            },
        })
    }

    if (sliderMobEl) {
        const slider_mob = new Swiper('.slider_mob', {
            slidesPerView: 1,
            loop: true,
            parallax: true,
            spaceBetween: 10,
            navigation: {
                nextEl: '.slider_mob__slide__arrow__next',
                prevEl: '.slider_mob__slide__arrow__prev',
            },
        })
    }

    if (heroscreenSliderEl) {
        const heroscreenSlider = new Swiper('.heroscreen_slider', {
            slidesPerView: 1,
            loop: true,
            slidesPerView: "auto",
            parallax: true,
            autoplay: {
                delay: 6000,
            },
            pagination: {
                el: '.heroscreen__pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.heroscreen__slide__arrow__next',
                prevEl: '.heroscreen__slide__arrow__prev',
            },
        })
    }

    if (mySwiperEl && mySwiper2El) {
        let swiper = new Swiper(".mySwiper", {
            spaceBetween: 8,
            slidesPerView: 10,
            freeMode: true,
            watchSlidesProgress: true,
        });
        let swiper2 = new Swiper(".mySwiper2", {
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiper,
            },
        });
    }
}

// пролистывание 
function scrollHidden() {
    const scrollWrapper = document.querySelectorAll(".scroll-wrapper");
    if (!scrollWrapper.length) return;

    const arrowPrev = document.querySelectorAll(".arrow-prev");
    const arrowNext = document.querySelectorAll(".arrow-next");
    let isDragging = false;
    let startX, scrollLeft;
    let scrollInterval;

    function startDrag(e) {
        isDragging = true;
        startX = e.clientX;
        scrollLeft = this.scrollLeft;
        this.style.cursor = "grabbing";
        this.style.userSelect = "none";
    }
    function doDrag(e) {
        if (!isDragging) return;
        const x = e.clientX;
        const walk = (x - startX); 
        this.scrollLeft = scrollLeft - walk;
    }
    function endDrag() {
        isDragging = false;
        this.style.cursor = "grab";
        this.style.removeProperty("user-select");
    }
    scrollWrapper.forEach(function (scrollWrapper, index) {
        scrollWrapper.addEventListener("mousedown", startDrag);
        scrollWrapper.addEventListener("mousemove", doDrag);
        scrollWrapper.addEventListener("mouseup", endDrag);
        scrollWrapper.addEventListener("mouseleave", endDrag);
        
        if (scrollWrapper.scrollWidth <= scrollWrapper.clientWidth) {
            arrowNext[index].classList.add("active");
        }
        
        scrollWrapper.addEventListener("scroll", function () {
            const maxScrollLeft = this.scrollWidth - this.clientWidth;
    
            if (this.scrollLeft === 0) {
                arrowPrev[index].classList.add("active");
            } else {
                arrowPrev[index].classList.remove("active");
            }
    
            if (this.scrollLeft >= maxScrollLeft - 10) {
                arrowNext[index].classList.add("active");
            } else {
                arrowNext[index].classList.remove("active");
            }
        });
    });
    
    
    arrowPrev.forEach(function(arrow) {
        arrow.addEventListener("click", function() {
            const scrollWrapper = this.closest(".scroll-wrapper-cont").querySelector(".scroll-wrapper");
            scrollWrapper.scrollBy({ left: -250, behavior: "smooth" });
    
            clearInterval(scrollInterval);
        });
    });

    arrowNext.forEach(function(arrow) {
        arrow.addEventListener("click", function() {
            const scrollWrapper = this.closest(".scroll-wrapper-cont").querySelector(".scroll-wrapper");
            scrollWrapper.scrollBy({ left: 250, behavior: "smooth" });

            clearInterval(scrollInterval);
        });
    });
}



// модальные окна
document.addEventListener('click', function(e) {
    let target = e.target;
    
    if (target.matches('[data-bs-toggle="modal"]')) {
        e.preventDefault();

        let modalId = target.getAttribute('data-bs-target'),
            modalElem = document.querySelector(modalId);

        modalReplace(modalElem, target);
        modalElem.classList.add('active');
        overlay.classList.add('active');
    }
});


// модальные окна
!function(e){"function"!=typeof e.matches&&(e.matches=e.msMatchesSelector||e.mozMatchesSelector||e.webkitMatchesSelector||function(e){for(let t=this,o=(t.document||t.ownerDocument).querySelectorAll(e),n=0;o[n]&&o[n]!==t;)++n;return Boolean(o[n])}),"function"!=typeof e.closest&&(e.closest=function(e){for(let t=this;t&&1===t.nodeType;){if(t.matches(e))return t;t=t.parentNode}return null})}(window.Element.prototype);

let overlay = document.querySelector('.js-overlay-modal'); 

document.addEventListener('DOMContentLoaded', function() {
    let modalButtons = document.querySelectorAll('[data-bs-toggle="modal"]'),
    closeButtons = document.querySelectorAll('.js-modal-close');

    document.addEventListener('click', function(e) {
        let target = e.target;
        if (target.matches('[data-bs-toggle="modal"]')) {
            e.preventDefault();

            let modalId = target.getAttribute('data-bs-target'),
                modalElem = document.querySelector(modalId);

            modalReplace(modalElem, target);
            modalElem.classList.add('active');
            overlay.classList.add('active');
        }
    });


    closeButtons.forEach(function(item){
        item.addEventListener('click', function(e) {
            let parentModal = this.closest('.m23_modal');
            let modalImg = parentModal.querySelector('.modal_img img');

            parentModal.classList.remove('active');
            overlay.classList.remove('active');

            if (modalImg) {
                setTimeout(function() {
                    modalImg.src = modalImg.dataset.originalSrc;
                }, 200);
            }

        });
    });

    document.body.addEventListener('keyup', function (e) {
        let key = e.keyCode;

        if (key == 27) {
            let activeModal = document.querySelector('.m23_modal.active');
            let modalImg = activeModal.querySelector('.modal_img img');
    
            activeModal.classList.remove('active');
            overlay.classList.remove('active');

            if (modalImg) {
                setTimeout(function() {
                    modalImg.src = modalImg.dataset.originalSrc;
                }, 200);
            }
        };
    }, false);

    overlay.addEventListener('click', function() {
        let activeModal = document.querySelector('.m23_modal.active');
        if (activeModal) {
            let modalImg = activeModal.querySelector('.modal_img img');
    
            activeModal.classList.remove('active');
            this.classList.remove('active');
            
            if (modalImg) {
                setTimeout(function() {
                    modalImg.src = modalImg.dataset.originalSrc;
                }, 200);
            }
        }
    });
}); 


// Функция подмены контента в модалке
function modalReplace(modalElem, triggerButton) {
    let recipient = triggerButton.getAttribute('data-bs-theme'),
        recipientImg = triggerButton.getAttribute('data-bs-img'),
        recipientBtn = triggerButton.getAttribute('data-bs-btn');

    let modalTitle = modalElem.querySelector('.modal_title'),
        modalBtn = modalElem.querySelector('.btn__form'),
        modalImg = modalElem.querySelector('.modal_img img'),
        modalBodyInputs = modalElem.querySelectorAll('.modal_input_theme');

    if (modalImg) {
        if (!modalImg.dataset.originalSrc) {
            modalImg.dataset.originalSrc = modalImg.src;
        }
    }

    if (recipient) {
        modalTitle.textContent = recipient;
        modalBodyInputs.forEach(input => {
            input.value = recipient;
        });
    }
    if (recipientImg) {
        modalImg.src = recipientImg;
    }
    if (recipientBtn) {
        modalBtn.textContent = recipientBtn;
    }
}


// Функция для инициализации тултипов
function initTooltips() {
    const tooltipElements = document.querySelectorAll('[data-tooltip]');
    if (!tooltipElements.length) return;

    const tooltip = document.createElement('div');
    tooltip.className = "tooltip_cont";
    document.body.appendChild(tooltip);

    document.addEventListener('mouseover', function(event) {
        const target = event.target.closest('[data-tooltip]');
        if (!target) return;

        const tooltipText = target.getAttribute('data-tooltip');
        tooltip.textContent = tooltipText;
        tooltip.style.display = 'block';

        if (tooltip.offsetWidth) {
            const rect = target.getBoundingClientRect();
            tooltip.style.left = `${rect.left + (rect.width - tooltip.offsetWidth) / 1.5}px`;
            tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 5}px`;
        }
    });

    document.addEventListener('mouseout', function(event) {
        const target = event.target.closest('[data-tooltip]');
        if (!target) return;

        tooltip.style.display = 'none';
    });
}